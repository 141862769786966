import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import levoEn from '../assets/images/logo-levo.png'
import levoFr from '../assets/images/logo-levo-french.png'

const Banner = props => {
  const { t, i18n } = useTranslation()

  return (
    <section id="banner" className="major">
      <div className="inner">
        <header>
          <h1 style={{ textAlign: 'center' }}>
            <img
              src={i18n.language === 'en' ? levoEn : levoFr}
              alt={t('iOS')}
              style={{ marginRight: 10, width: '7em' }}
            />
          </h1>
        </header>
      </div>
    </section>
  )
}

export default Banner
