import React from 'react'
import HomeLayout from '../components/HomeLayout'

class HomeIndex extends React.Component {
  render() {
    return <HomeLayout />
  }
}

export default HomeIndex
