import React from 'react'
import Helmet from 'react-helmet'

import Layout from './layout'
import Banner from './Banner'

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default () => {
  const { t } = useTranslation()
  const motto = t('motto')

  return (
    <Layout>
      <Helmet
        title="Levo"
        meta={[
          { name: 'description', content: motto },
          { name: 'keywords', content: motto },
        ]}
      ></Helmet>
      <Banner />
      <div id="main">
        <section id="one" className="tiles">
          <div style={{ flex: 1, justifyContent: 'center' }}>
            <p
              style={{
                textAlign: 'center',
              }}
            >
              {t('betaEnd')}
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}
